import { sectionTypes } from '@lifedot/constants/searchChoices'

export const orderedSectionType = <
  T extends {
    code: string
    [x: string]: unknown
  }
>(
  choices: T[]
): T[] =>
  sectionTypes.reduce<T[]>((res, { code }) => {
    const choice = choices.find((choice) => choice.code === code)
    return choice ? [...res, choice] : res
  }, [])

export const orderedSectionTypeCodes = (choices: string[]): string[] =>
  sectionTypes.reduce<string[]>(
    (res, { code }) =>
      choices.some((choice) => choice === code) ? [...res, code] : res,
    []
  )
