import { eventPush, ga4EventPush } from '../use-event-tracking'
import { useEffect } from 'react'
import { ElementCategory } from '@lifedot/constants/elementCategories'

export const useViewTracking = ({
  category,
  label
}: {
  category: string
  label: string
}): void => {
  useEffect(() => {
    eventPush({
      action: 'view',
      category,
      label
    })
  }, [])
}

export const useGa4ViewTracking = ({
  elementCategory,
  elementLabel
}: {
  elementCategory?: ElementCategory
  elementLabel?: string
}) => {
  useEffect(() => {
    if (elementLabel)
      ga4EventPush({
        event: 'view_contents',
        elementCategory: elementCategory,
        elementLabel: elementLabel
      })
  }, [elementLabel])
}
