import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'

const styles = {
  root: css({
    maxWidth: 860,
    margin: 'auto'
  })
}

type SingleColumnProps = {
  children: ReactNode
}

export const SingleColumn: FC<SingleColumnProps> = ({ children }) => (
  <div css={styles.root}>{children}</div>
)
