import { PriceMaxCode, PriceMinCode } from '@lifedot/constants/searchChoices'

export const convertPriceRange = (key: string | null): number | null => {
  if (key === PriceMaxCode.PRICE_MAX_1 || key === PriceMinCode.PRICE_MIN_1)
    return 300000
  if (key === PriceMaxCode.PRICE_MAX_2 || key === PriceMinCode.PRICE_MIN_2)
    return 500000
  if (key === PriceMaxCode.PRICE_MAX_3 || key === PriceMinCode.PRICE_MIN_3)
    return 1000000
  if (key === PriceMaxCode.PRICE_MAX_4 || key === PriceMinCode.PRICE_MIN_4)
    return 1500000
  return null
}
