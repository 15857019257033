import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

export const useLoading = (): boolean => {
  const [loading, setLoading] = useState(false)
  const router = useRouter()

  const handleChangeStart = useCallback(() => setLoading(true), [])
  const handleChangeEnd = useCallback(() => setLoading(false), [])

  useEffect(() => {
    router.events.on('routeChangeStart', handleChangeStart)
    router.events.on('hashChangeStart', handleChangeStart)
    router.events.on('routeChangeComplete', handleChangeEnd)
    router.events.on('hashChangeComplete', handleChangeEnd)
    router.events.on('routeChangeError', handleChangeEnd)
    return () => {
      router.events.off('routeChangeStart', handleChangeStart)
      router.events.off('hashChangeStart', handleChangeStart)
      router.events.off('routeChangeComplete', handleChangeEnd)
      router.events.off('hashChangeComplete', handleChangeEnd)
      router.events.off('routeChangeError', handleChangeEnd)
    }
  }, [handleChangeEnd, handleChangeStart, router])

  return loading
}
