export const group = <T extends Record<string, unknown>>(
  arr: Array<T>,
  key: keyof T
): Record<string, Array<T>> =>
  arr.reduce<Record<string, Array<T>>>((r, a) => {
    const newKey = a[key]
    if (typeof newKey !== 'string') return r
    r[newKey] = [...(r[newKey] ?? []), a]
    return r
  }, {})
