import { FC } from 'react'
import { css, keyframes } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { elevation } from '@lifedot/styles/elevation'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { rgba } from 'emotion-rgba'
import { zIndex } from '@lifedot/styles/zIndex'

const animation = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(359deg)' }
})

const styles = {
  overlay: css({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: zIndex.progress
  }),
  spinnerBox: css({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 200,
    height: 150,
    backgroundColor: palette.white,
    borderRadius: 4,
    boxShadow: elevation[1],
    [mq('sp')]: {
      width: 160,
      height: 130
    }
  }),
  spinner: css({
    width: 70,
    height: 70,
    border: `8px solid ${rgba(palette.main, 0.3)}`,
    borderTopColor: rgba(palette.main, 0.7),
    borderRadius: '50%',
    animation: `${animation} .8s linear infinite`,
    [mq('sp')]: {
      width: 60,
      height: 60
    }
  }),
  title: css({
    marginTop: 8,
    fontWeight: 'bold'
  })
}

export const Progress: FC = () => {
  return (
    <div css={styles.overlay}>
      <div css={styles.spinnerBox}>
        <div css={styles.spinner} />
        <p css={[typography.textM, styles.title]}>読み込み中…</p>
      </div>
    </div>
  )
}
